import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import logo from "../logo-pink-white.svg"
import "../styles/ChatInterface.css"
import { useUser } from "../contexts/UserContext"
import Message from "./Message"
import { useNavigate } from "react-router-dom"

const ChatInterface = () => {
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [socket, setSocket] = useState(null)
  const [workingMessage, setWorkingMessage] = useState("")
  const [mealData, setMealData] = useState(null) // Meal data state
  const [response, setResponse] = useState("")
  const navigate = useNavigate()
  const { userID, setUserID } = useUser()
  //   const { userID } = useParams()

  // const { user } = useUser()
  // const userID = user.userID
  // const { userID } = useUser() // directly destructure userID instead of user
  // if (!userID) {
  //   const userID = localStorage.getItem('userID');
  // }
  useEffect(() => {
    if (!userID) {
      const storedUserID = localStorage.getItem("userID")
      if (storedUserID) {
        setUserID(storedUserID) // Update the context if userID is found in localStorage
      } else {
        navigate("/login") // Redirect to login page if no userID is found
      }
    }
  }, [userID, setUserID, navigate])

  console.log("userID:", userID)

  const scrollToBottom = useCallback(() => {
    console.log("Attempting to scroll to bottom...")
    const chatContainer = document.querySelector(".ChatContainer")
    if (chatContainer) {
      console.log("Container found, scrolling now...")
      requestAnimationFrame(() => {
        chatContainer.scrollTop = chatContainer.scrollHeight
      })
    } else {
      console.log("Container not found.")
    }
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        console.log("Fetching messages for user:", userID)
        const response = await axios.get(
          `https://api.syfthealth.app/v1/getMessages/${userID}`
        )
        setMessages(response.data)
        console.log("Messages fetched:", response.data)
        scrollToBottom()
      } catch (error) {
        console.error("Error fetching messages:", error.toString())
      }
    }

    fetchMessages()
  }, [userID, scrollToBottom])

  const cleanTypingMessages = () => {
    console.log("Cleaning typing messages")
    setMessages((prevMessages) => {
      return prevMessages.filter((message) => message.sender !== "bottyping")
    })
    setResponse("")
    // console.log("Cleaned typing messages")
    scrollToBottom()
  }

  // useEffect(() => {
  //   const newSocket = new WebSocket(
  //     "wss://17yh6zrnkj.execute-api.eu-west-1.amazonaws.com/production/"
  //   )
  //   newSocket.onmessage = (event) => {
  //     try {
  //       const jsonData = JSON.parse(event.data)

  //       if (jsonData.mealData) {
  //         // setTimeout(() => {
  //         //   fetchMessages()
  //         // }, 1000)
  //         console.log("Has mealDataNested XX: ", jsonData)
  //         let mealDataNew = {}
  //         mealDataNew.mealName = jsonData.mealName
  //         mealDataNew.mealData = jsonData.mealData
  //         mealDataNew.nutritionData = jsonData.mealData
  //         mealDataNew.nutritionDataNested = jsonData.mealDataNested
  //         console.log("mealDataNew", mealDataNew)
  //         setMealData(jsonData.mealDataNew) // assuming you have a state setter called setMealData
  //         const mealMessage = {
  //           text: JSON.stringify(jsonData.mealData),
  //           mealName: jsonData.mealName,
  //           mealData: JSON.stringify(mealDataNew),
  //           nutritionData: JSON.stringify(jsonData.mealData),
  //           nutritionDataNested: JSON.stringify(jsonData.mealDataNested),
  //           messageText: JSON.stringify(jsonData.mealName),
  //           sender: "Syft",
  //           type: "syftbot",
  //           image:
  //             "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
  //         }
  //         console.log("Meal message:", JSON.stringify(mealMessage))
  //         setMessages((prevMessages) => [...prevMessages, mealMessage])
  //         cleanTypingMessages()
  //         scrollToBottom()
  //       }
  //       if (jsonData.response) {
  //         console.log("Contains response")
  //         console.log(jsonData)
  //         const serverMessage = {
  //           text: jsonData.response,
  //           sender: "Syft",
  //           type: "syftbotchat",
  //           image:
  //             "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
  //           syft_thread_id: parseInt(jsonData.syft_thread_id),
  //         }
  //         setMessages((prevMessages) => [...prevMessages, serverMessage])
  //         cleanTypingMessages()
  //         setIsSending(false)
  //         scrollToBottom()
  //       }
  //       // ... (rest of the message handling logic)
  //     } catch (error) {
  //       if (event.data.includes("Syft Vision")) {
  //         console.log("Contains response")
  //         // console.log(jsonData.response)
  //         const visionMessage = {
  //           text: event.data,
  //           sender: "Syft",
  //           type: "syftbotvision",
  //           image:
  //             "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
  //         }
  //         setMessages((prevMessages) => [...prevMessages, visionMessage])
  //       } else {
  //         console.log("Received non-JSON message:", event.data)
  //         setResponse(event.data)
  //         scrollToBottom()
  //       }
  //     }
  //     scrollToBottom()
  //   }

  //   setSocket(newSocket)

  //   return () => {
  //     newSocket.close()
  //   }
  // }, [scrollToBottom])

  const sendMessage = async () => {
    if (!message.trim() || isSending) {
      // alert("No input to send.")
      return
    }
    setIsSending(true)

    // try {
    //   if (!socket || socket.readyState !== WebSocket.OPEN) {
    //     alert("WebSocket is not open.")
    //     setIsSending(false)
    //     return
    //   }

    const socket = new WebSocket(
      "wss://17yh6zrnkj.execute-api.eu-west-1.amazonaws.com/production/"
    )
    socket.onopen = () => {
      const newMessage = {
        text: message.trim(),
        sender: "user",
        type: "user",
        image: null,
        response: null,
        newUserImage: null,
      }

      socket.send(
        JSON.stringify({
          userID, // Assuming userID is globally accessible or stored in state/context
          message: newMessage,
        })
      )
      setMessages((prev) => [...prev, newMessage])

      const botTyping = {
        text: "",
        sender: "bottyping",
        type: "bottyping",
        image:
          "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
      }

      setMessages((prevMessages) => [...prevMessages, botTyping])
      setMessage("")
      scrollToBottom()
    }

    socket.onmessage = (event) => {
      try {
        const jsonData = JSON.parse(event.data)

        if (jsonData.mealData) {
          // setTimeout(() => {
          //   fetchMessages()
          // }, 1000)
          console.log("Has mealDataNested XX: ", jsonData)
          let mealDataNew = {}
          mealDataNew.mealName = jsonData.mealName
          mealDataNew.mealData = jsonData.mealData
          mealDataNew.nutritionData = jsonData.mealData
          mealDataNew.nutritionDataNested = jsonData.mealDataNested
          console.log("mealDataNew", mealDataNew)
          setMealData(jsonData.mealDataNew) // assuming you have a state setter called setMealData
          const mealMessage = {
            text: JSON.stringify(jsonData.mealData),
            mealName: jsonData.mealName,
            mealData: JSON.stringify(mealDataNew),
            nutritionData: JSON.stringify(jsonData.mealData),
            nutritionDataNested: JSON.stringify(jsonData.mealDataNested),
            messageText: JSON.stringify(jsonData.mealName),
            sender: "Syft",
            type: "syftbot",
            image:
              "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
          }
          console.log("Meal message:", JSON.stringify(mealMessage))
          setMessages((prevMessages) => [...prevMessages, mealMessage])
          cleanTypingMessages()
          scrollToBottom()
        }
        if (jsonData.response) {
          console.log("Contains response")
          console.log(jsonData)
          const serverMessage = {
            text: jsonData.response,
            sender: "Syft",
            type: "syftbotchat",
            image:
              "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
            syft_thread_id: parseInt(jsonData.syft_thread_id),
          }
          setMessages((prevMessages) => [...prevMessages, serverMessage])
          cleanTypingMessages()
          setIsSending(false)
          scrollToBottom()
        }
        // ... (rest of the message handling logic)
      } catch (error) {
        if (event.data.includes("Syft Vision")) {
          console.log("Contains response")
          // console.log(jsonData.response)
          const visionMessage = {
            text: event.data,
            sender: "Syft",
            type: "syftbotvision",
            image:
              "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
          }
          setMessages((prevMessages) => [...prevMessages, visionMessage])
        } else {
          console.log("Received non-JSON message:", event.data)
          setResponse(event.data)
          scrollToBottom()
        }
      }
      scrollToBottom()
    }
    socket.onerror = (error) => {
      console.error("WebSocket Error:", error)
      setIsSending(false)
    }

    socket.onclose = () => {
      console.log("WebSocket closed")
      setIsSending(false)
    }

    // setSocket(newSocket)

    // return () => {
    //   newSocket.close()
    // }
    // } catch (error) {
    //   console.error("Error sending message:", error)
    // } finally {
    //   // setIsSending(false)
    // }
  }

  return (
    <div>
      <div className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div className="ChatContainer">
        {messages.map((msg) => (
          <Message
            key={msg.syft_thread_id}
            text={msg.text}
            sender={msg.sender}
            type={msg.type}
            image={msg.image}
            response={msg.response}
            userImage={msg.userImage}
            newUserImage={msg.newUserImage}
            timestamp={msg.timestamp}
            mealData={msg.mealData}
            mealName={msg.mealName}
            syft_thread_id={msg.syft_thread_id}
            syft_thread_rating={msg.syft_thread_rating}
            isFirst={msg.isFirst} // Assuming you determine if it's the first message elsewhere
          />
        ))}
        {response && <p className="response">{response}</p>}
      </div>
      <div className="MessageInputContainer">
        <textarea
          className="MessageInput"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && !e.shiftKey && sendMessage()}
          placeholder="Type a message..."
          disabled={isSending}
        />
        <button
          className="send-button"
          onClick={sendMessage}
          disabled={isSending || !message.trim()}
        >
          Send
        </button>
      </div>
    </div>
  )
}

export default ChatInterface
